import React from 'react';
import './IconList.scss';
import LazyLoad from 'react-lazy-load'

export const IconList = ({ iconList = null }) => {
  if (!iconList) return null;
  return (
    <div className="icon-list">
      <ul>
        {iconList.length > 0 && iconList.map(({ icon = 'truck', text = 'Learn more' }) => (
          <li><LazyLoad><span className={icon}/></LazyLoad>{text}</li>
        ))}
      </ul>
    </div>
  );
}
