import React from 'react';
import Image from '../components/Image';
import { SearchForm } from '../components/SearchForm';
import { Carousel } from '../components/Carousel';
import './HomepageBanner.scss';
import HomePageImageQuery from '../components/Image/HomePageImageQuery'

export const HomepageBanner = ({ heading, subtitle, images, location }) => {
  return (
    <>
      <section className="homepage-banner">
        {location.pathname === "/" && <div className="home-base"><HomePageImageQuery className="background" objectPosition="top center"/></div>}

        {images && (
          <div className="banner-images">
            <Carousel
              dotsDisabled
              buttonsDisabled
              infinite
              autoPlay
              duration={500}
              autoPlayInterval={2500}
              id="homepage-banner-carousel"
            >
              {images.map((slide, index) =>
                <>{location.pathname === "/" && index === 0 ? <div className="slide-none" /> : <div className="slide"><Image image={slide.image} /></div>}</>
              )}
            </Carousel>
          </div>
        )}
        <div className="banner-text">
          <h1 dangerouslySetInnerHTML={{__html: heading}} />
          {subtitle && <p dangerouslySetInnerHTML={{__html: subtitle}} />}
        </div>
      </section>
      <div className="homepage-banner-search-container">
        <div className="wrapper">
          <span className="homepage-banner-search-container-heading">Search for a service</span>
          <SearchForm />
        </div>
      </div>
    </>
  );
}
