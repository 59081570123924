import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LinkTiles } from './LinkTiles';

export const ProductCategoryTiles = ({ heading, content, categories, site }) => {
  if (!categories) return null;
  const { allWordpressWpServicesCat } = useStaticQuery(graphql`
    {
      allWordpressWpServicesCat {
        nodes {
          id
          wordpress_id
          link
          name
          description
          slug
          taxonomy {
            slug
          }
          acf {
            featureImage {
              localFile {
                childImageSharp {
                  fixed(width: 600, height:250, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const tiles = categories.map((cat) => {
    const categoryData = allWordpressWpServicesCat.nodes.find((innerCat) => innerCat.wordpress_id === cat.category);
    const { featureImage = null } = categoryData.acf;
    const tileLink = categoryData.link.replace(`/${categoryData.taxonomy.slug}`, '');
    return {
      tileTitle: categoryData.name,
      tileContent: categoryData.description,
      tileImage: featureImage,
      tileLink: `/${site.siteMetadata.productsSlug}${tileLink}`
    };
  })
  return (
    <LinkTiles
      heading={heading}
      content={content}
      tilesHeading="Browse our services by category"
      tiles={tiles}
    />
  );
}
